// extracted by mini-css-extract-plugin
export var buttonContainer = "workspace-module--buttonContainer--587d8";
export var divider = "workspace-module--divider--3805f";
export var icon = "workspace-module--icon--8e93a";
export var iconContainer = "workspace-module--icon-container--2bb51";
export var section1Container = "workspace-module--section1-container--17371";
export var section1Content = "workspace-module--section1-content--bd726";
export var section1Header = "workspace-module--section1-header--37d0b";
export var section1Text = "workspace-module--section1-text--8e3cb";
export var section2 = "workspace-module--section2--5d985";
export var section2Content = "workspace-module--section2-content--f1393";
export var section3 = "workspace-module--section3--df160";
export var section3Text = "workspace-module--section3Text--f3430";