import React from "react"
import Layout from "../components/layouts/Layout.js"
import * as styles from "../stylesheets/pages/workspace.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import ContactForm from "../components/contactForm.js"

const GoogleWorkspace = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicGoogleWorkspace {
        data {
          banner_image {
            alt
            url
          }
          contact_description {
            text
          }
          contact_header {
            text
          }
          section_1_content {
            content_block {
              text
            }
          }
          section_1_header {
            text
          }
          section_1_icons {
            icon {
              alt
              url
            }
          }
          section_2_buttons {
            text {
              text
            }
            url {
              text
            }
          }
          section_2_content {
            text
          }
          section_2_header {
            text
          }
          section_2_video_url {
            text
          }
          title {
            text
          }
        }
      }
    }
  `).prismicGoogleWorkspace.data

  const icons = data.section_1_icons.map((icon, index) => {
    return (
      <img
        src={icon.icon.url}
        alt={icon.icon.alt}
        className={styles.icon}
        key={index}
      />
    )
  })

  const section_2_buttons = data.section_2_buttons.map((button, index) => {
    return (
      <a href={button.url.text} key={index}>
        <button>{button.text.text}</button>
      </a>
    )
  })

  return (
    <Layout
      title="Google Workspace Migrations and Support - TechCollective"
      description="Need help moving your company to Google? We provide seamless migrations from legacy email providers, as well as ongoing support."
    >
      <div className={styles.section1Container}>
        <div className={styles.section1Header}>
          <img src={data.banner_image.url} alt={data.banner_image.alt} />
          <h2>stability, flexibility & security</h2>
        </div>
        <div className={styles.section1Content}>
          <div className={styles.iconContainer}>{icons}</div>
          <div className={styles.section1Text}>
            <h3>The Power of Google In Your Organization</h3>
            <ul>
              <li>
                Do you have control over your organization's communications?
              </li>
              <li>Does your current email system meet all your needs?</li>
              <li>
                Can your calendars, contacts and email be easily accessed
                anywhere?
              </li>
              <li>
                Are you jury-rigging old and new tech to get business done?
              </li>
              <li>
                Is your organization spending a fortune every month on email
                hosting?
              </li>
              <li>Are you getting a ton of spam in your inbox?</li>
            </ul>
            <div className={styles.divider} />
            <h3>There Is A Better Way</h3>
            <p>
              Stop settling for outdated and clumsy systems. Now for around $50
              per employee per year (free for non-profits!) your organization
              can have the power of Google at its disposal. Once the dust
              settles from the migration, you will have a fully integrated
              system for mail, contacts and calendars. You will also have the
              option to use a file sharing service Google Drive on top of it.
              Basically everything you could do ten years ago with a local
              server can now be done securely online
            </p>
            <div className={styles.divider} />
            <h3>How We Get You There</h3>
            <p>
              The process of migration can be very tricky and time consuming
              from a technical standpoint. Your current environment plays a huge
              role in the process, but we will guide you every step of the way.
              From the back-end preparations to the data transfer and from
              device configuration to employee training.
            </p>
            <p>
              For simplicity we separate things into two different project
              types; Google Apps & Google Drive respectively. Why? Because in
              our experience we have found that most organizations need their
              email, contacts & calendars moved first. In fact many do not need
              to use Google Drive right away, or at all. We can of course do
              both, but give you the option of staggering the migrations to
              avoid unneeded complexity.
            </p>
          </div>
        </div>
        <div className={styles.section2}>
          <h2>Compare To Your Current Email Provider</h2>
          <div className={styles.section2Content}>
            <ul>
              <li>A minimum of 30 gigabytes of storage per employee.</li>
              <li>
                Gmail web interface as well as IMAP and Outlook compatibility.
              </li>
              <li>The best spam filtering solution on the market.</li>
              <li>
                Integrated calendars - share them internally and externally.
              </li>
              <li>Contact management and global address book options.</li>
              <li>Text, video and voice-based chat.</li>
              <li>Cloud-based file syncing and storage.</li>
              <li>Live document collaboration.</li>
              <li>Robust security tools to safeguard your data.</li>
              <li>Access from any device or operating system you choose.</li>
              <li>All connections are automatically encrypted.</li>
              <li>24/7 phone and email support directly from Google.</li>
            </ul>
            <iframe
              width="100%"
              style={{ maxWidth: "460px" }}
              height="320"
              src="https://www.youtube.com/embed/bE31y5HbukA"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Introducing Google Workspace"
            ></iframe>
          </div>
          <div className={styles.buttonContainer}>
            {/* <button>Google's FAQ</button>
            <button>Is it Secure?</button> */}
            {section_2_buttons}
          </div>
        </div>
        <div className={styles.section3}>
          <h2>Request a Free Estimate</h2>
          <p className={styles.section3Text}>
            Please fill out the form below and we'll contact you regarding a
            migration. Don't worry if you aren't sure about certain details. We
            can figure out everything during the discovery phase, but it does
            help us when estimating your migration costs. We don't sell your
            information to any third parties!
          </p>
          <ContactForm
            type={"gsuite"}
            width={"600px"}
            customFields={[
              { name: "Current Email Provider", required: false },
              { name: "Number of Employees", required: false },
            ]}
          />
        </div>
      </div>
    </Layout>
  )
}

export default GoogleWorkspace
